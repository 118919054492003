.footer {
    // margin-top: 7.5rem;
    &__list {
        font-size: 1.6rem;
        text-transform: uppercase;
        color: var(--black);

        & li:not(:last-child) {
            margin-bottom: 2rem;
        }

        & a {
            color: currentColor;
        }

        & svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    &__icons {
        display: flex;
        justify-content: flex-end;

        margin-top: -3.3rem;

        & li:first-child {
            margin-right: 2rem;
        }

        & svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    &__form {
        margin: 3.5rem 0 2.5rem;

        & label {
            font-size: 1.6rem;
            text-transform: uppercase;
            color: var(--black);
        }

        & input {
            width: 100%;
            padding: 1.05rem 2rem;
            margin: 1.5rem 0;

            border: 0.1rem solid var(--black);
            border-radius: 0.5rem;
        }

        & input::placeholder {
            font-size: 1.4rem;
            font-family: $font-primary;
            color: var(--gray-400);
        }

        & button {
            min-width: 16.5rem;
        }
    }
}

@include rmin($bp-tablet-m) {
    .footer {
        display: flex;
        justify-content: space-between;
        margin: 0 0 5rem;

        &__wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;

            & li:nth-child(2) {
                order: 3;
            }
        }

        &__icons {
            order: 2;
            margin-top: 4rem;
        }

        &__icons {
            & svg {
                width: 6rem;
                height: 6rem;
            }
        }

        &__form {
            max-width: 51.5rem;
            margin: unset;

            & input {
                padding: 1.55rem 2rem;
                margin: 2.5rem 0;
            }

            & input::placeholder {
                font-size: 1.6rem;
            }

            & button {
                min-width: 22rem;
            }
        }
    }
}
