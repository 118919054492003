.header {
    --icon-size: 2rem;
    padding: 1rem 0;

    &__main-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 0 -2rem;
        padding: 0 1.3rem 0 1.7rem;
    }

    &__logo {
        width: 9rem;
        height: 4rem;

        margin-left: 5.5rem;
    }

    &__icons svg {
        width: var(--icon-size);
        height: var(--icon-size);
    }

    &__icons {
        display: flex;
    }

    &__icons li {
        & {
            width: var(--icon-size);
            height: var(--icon-size);
        }

        &:not(:first-child) {
            margin-left: 1.5rem;
        }

        &:nth-child(3) {
            display: none;
        }
    }

    //burger
    &__menu {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: -1rem;
            width: 120%;
            height: 0.1rem;
            // background: var(--primary-400);
        }
    }

    &__menu-toggler {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0;
        padding: 0;

        background-color: transparent;
        border: none;
        overflow: hidden;

        transition: background-color 0.2s, color 0.3s;

        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            top: 70%;
            left: 50%;

            width: 2.5rem;
            height: 0.2rem;

            background-color: var(--black);
            transform: translateX(-50%);
            transition: all 0.2s;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: var(--black);
        }

        span::before {
            content: "";
            position: absolute;
            left: 50%;

            display: block;
            width: 2.5rem;
            height: 0.2rem;

            background-color: var(--black);
            transform: translateX(-50%) rotateZ(0);
            transition: all 0.2s linear;
        }

        span::before {
            top: -0.8rem;
        }
    }

    &__menu-toggler--opened {
        &::before {
            transform: rotate(-45deg) translateX(-20%) translateY(-550%);
        }

        span::before {
            top: 0;
            width: 2.4rem;
            height: 0.2rem;
            transform: translateX(-45%) rotateZ(45deg);
        }
    }

    //end of menu
}

.nav-menu {
    &__list-wrapper {
        display: none;

        width: 100%;
        height: 100%;
        padding: 1.5rem 2.5rem;
        background-color: var(--white);
        box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.05);

        z-index: 99999;
    }

    &__list-wrapper--opened {
        position: absolute;
        top: 6rem;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        overflow-y: scroll;

        display: block;
    }

    &__img {
        display: none;
    }

    &__second {
        display: none;

        a {
            display: inline-block;
            width: 100%;
            color: currentColor;
        }
    }

    &__title-list {
        display: flex;
        justify-content: center;
    }

    &__second--opened {
        display: block;
        margin-left: 1.5rem;
        background-color: var(--white);
    }

    &__second-link {
        position: relative;

        &::after {
            position: absolute;
            right: 0;
            top: 2rem;
            content: "\002B";

            font-size: 2.2rem;
        }

        &--opened::after {
            content: "\02DF";
            margin-top: 0.5rem;
            font-size: 2.6rem;
        }
    }

    &__link-title {
        display: none;
    }

    &__list-inner {
        padding-bottom: 5rem;
        color: var(--black);
    }

    &__list-inner ul:nth-child(1) li {
        padding: 2rem 0;
        text-transform: uppercase;
        font-size: 1.6rem;

        & a {
            color: currentColor;
        }

        &:not(:last-child) {
            border-bottom: 0.1rem solid var(--gray-300);
        }
    }

    &__list-inner ul:nth-child(1) li ul li {
        padding: 1.5rem 0 1rem;
        text-transform: capitalize;
    }
}

@include rmin($bp-tablet-m) {
    .header {

        --icon-size: 2.5rem;

        padding: 2.5rem 0 0;
        position: relative;

        &__logo {
            width: unset;
            height: 7.4rem;

            margin-left: unset;
            margin-right: 6rem;
        }

        //menu
        &__menu {
            position: unset;
        }

        &__icons svg {
            width: var(--icon-size);
            height: var(--icon-size);
        }

        &__icons li {
            & {
                width: var(--icon-size);
                height: var(--icon-size);
            }

            &:not(:first-child) {
                margin-left: var(--icon-size);
            }

            &:nth-child(3) {
                display: none;
            }
        }

        &__menu-toggler {
            position: unset;
            display: none;
        }

        &__main-content {
            align-items: flex-end;
        }

        //end of menu
        // &__icons {
        //     margin-bottom: 0.5rem;
        // }
        &__icons li:nth-child(3) {
            display: inline-flex;
        }
    }

    .nav-menu {

        &__link-men:hover &__list-wrapper,
        &__link-women:hover &__list-wrapper {
            display: block !important;
            position: absolute;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &__link-men:hover &__list-wrapper,
        &__link-women:hover &__list-wrapper {
            display: block !important;
            position: absolute;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &__link-men {
            display: flex;
            padding: unset;
        }

        &__link-women {
            position: unset;
        }

        &__img {
            display: block;

            width: 55.4rem;
            height: 35rem;

            overflow: hidden;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__link-title {
            display: block;

            font-size: 1.8rem;
            text-transform: uppercase;
        }

        &__list-wrapper {
            height: unset;
            max-height: 43.9rem;
        }

        &__second-link,
        &__second-link::after {
            position: unset;
            font-size: 0 !important;
        }

        &__second-link:nth-of-type(2) {
            display: none;
        }

        &__second {
            display: flex;
            flex-wrap: wrap;
            margin-top: -2rem;
        }

        &__list-inner {
            display: flex;
            justify-content: space-between;

            max-width: 120rem;
            margin: 0 auto;
        }

        &__list-wrapper {
            top: 9.9rem;
            padding: 4.1rem 0;
        }

        &__list--women,
        &__list--men {
            width: 50%;
        }

        &__tb-hidden,
        &__sec-item {
            display: none;
        }

        &__list-inner {
            padding-bottom: unset;
        }

        &__list-inner ul:nth-child(1) li ul li {
            flex-basis: 33.3%;
            padding: 2.5rem 0 0;
            display: block !important;
        }

        &__list-inner ul:nth-child(1) li:not(:last-child) {
            border-bottom: unset;
        }

        &__title-list li:nth-of-type(1) &__link-title {
            margin-right: 10rem;
        }
    }
}
