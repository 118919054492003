html {
    font-size: 62.5%; //standart size is now 10px
}

body {
    font-size: 1.6rem;
    font-family: $font-primary;
    line-height: 1.4;

    background-color: var(--white);
    overflow-x: hidden;
}

.container-wrap {
    max-width: $max-width;
    margin: 0 auto;
    padding: 0 2rem;
}

img,
video {
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

textarea {
    resize: none;
}

.no-scroll {
    overflow-y: hidden;
    // padding-right: 1.7rem;
}

.visually-hidden {
    position: absolute !important;
    overflow: hidden;

    width: 0.1rem;
    height: 0.1rem;

    margin: -0.1rem;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
}

//titles
.main-title h1 {
    font-size: 2.5rem;
    font-weight: 400;

    text-transform: uppercase;
    color: var(--black);
}

@include rmin($bp-tablet-m) {
    .main-title h1 {
        font-size: 4rem;
    }
}

.t-crop {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &--one-l {
        -webkit-line-clamp: 1;
    }

    &--two-l {
        -webkit-line-clamp: 2;
    }

    &--tree-l {
        -webkit-line-clamp: 3;
    }

    &--four-l {
        -webkit-line-clamp: 10;
    }
}

//product
.product {
    &__item {
        display: flex;
        width: 48%;
        margin-top: 3.4rem;
        text-align: center;

        & a {
            display: flex;
            flex-direction: column;
            color: currentColor;
        }

        & p {
            margin-top: 1.5rem;
            font-size: 1.3rem;
            white-space: normal;
        }

        & span {
            display: inline-block;
            margin-top: 1rem;
            font-size: 1.6rem;
        }
    }

    // &__text-inner {
    //     margin-top: auto;
    // }

    &__item-img__wrapper {
        position: relative;
        width: 100%;
        padding-top: 100%;
        background-color: var(--gray-100);
    }

    &__item-img__wrapper img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__accordeon {
        summary {
            position: relative;

            display: flex;
            align-items: center;

            list-style: none;
            outline: none;
            font-size: 2rem;
            text-transform: uppercase;

            &::after {
                content: "\002B";
                position: absolute;
                right: 0;
                top: -1rem;

                font-size: 2.5rem;
            }
        }

        summary::-webkit-details-marker {
            display: none;
        }

        details[open] summary {
            &::after {
                content: "\02DF";
                top: 0;
                font-size: 3rem;
            }
        }

        li {
            position: relative;
            padding: 1.5rem 0 1rem;

            cursor: pointer;
            text-align: left;
        }

        & ul:nth-child(2) li {
            padding: 2.5rem 0;
            font-size: 1.8rem;
            color: var(--gray-500);
        }

        li:not(:last-child)::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1rem;
            background-color: var(--black);
            opacity: 0.5;
        }

        ul:nth-child(2) li {
            &::after {
                content: unset;
            }
        }

        summary details {
            cursor: pointer;

            ::-webkit-details-marker {
                display: none;
            }
        }
    }

    //input
    &__input {
        width: 100%;

        font-family: $font-primary;
        border: 0.1rem solid var(--gray-500);
        border-radius: 0.5rem;


        &:placeholder {
            font-family: $font-primary;
        }

        &:required {
            border: 0.1rem solid $danger;
        }

        &:focus {
            border: 0.1rem solid var(--black);
        }
    }

    //back btn
    &-back__btn-inner {
        display: inline-flex;
        margin: 3.5rem auto 0;
    }

    &-back__btn {
        padding: 1.65rem 2rem !important;
        font-size: 1.6rem !important;
        line-height: 1.6rem;

        & svg {
            width: 2rem;
            height: 1rem;
            margin-right: 1rem;
            fill: var(--white);
        }
    }

    &-load__btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 20rem;

        padding: 1.4rem !important;

        font-size: 1.6rem !important;
        text-transform: uppercase;
    }

    &-load__btn-ico {
        width: 2rem;
        height: 2rem;
        margin-right: 1.1rem;
        fill: var(--white);
    }

    //load btn
    &-load__btn-inner {
        display: flex;
        justify-content: center;
        margin: 3.5rem auto 0;
    }

    &-load__btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 20rem;

        padding: 1.4rem !important;

        font-size: 1.6rem !important;
        text-transform: uppercase;
    }

    &-load__btn-ico {
        width: 2rem;
        height: 2rem;
        margin-right: 1.1rem;
        fill: var(--white);
    }
}

@include rmin($bp-phone-l) {
    .product {
        &__item {
            width: 31%;
        }
    }
}

@include rmin($bp-tablet) {
    .product {
        &__item {
            width: 23%;
        }
    }
}

@include rmin($bp-tablet-m) {
    .product {
        &__item {
            margin-top: 5rem;

            & p {
                margin-top: 2.5rem;
                font-size: 1.75rem;
            }

            & span {
                font-size: 2rem;
            }
        }

        //load btn
        &-load__btn-inner {
            margin: 5rem auto 0;
        }

        &-load__btn {
            min-width: 25rem;
            padding: 1.6rem !important;
            font-size: 2rem !important;
        }

        &-load__btn-ico {
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 1.4rem;
        }
    }
}

///центрирование

@mixin d-flex($display, $direction, $justify, $align) {
    @if $display==""and $display==flex {
        display: flex;
    }

    @else if $display==inline-flex {
        display: inline-flex;
    }

    flex-wrap: wrap;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

.d-flex {
    display: flex;
    flex-wrap: wrap;

    //vertical
    &--vertical-centered {
        @include d-flex(flex, column, center, center);
        text-align: center;
    }

    &--vertical-started {
        @include d-flex(flex, column, flex-start, flex-start);
    }

    &--vertical-ended {
        @include d-flex(flex, column, flex-end, flex-end);
    }

    &--horizontal-centered {
        @include d-flex(flex, row, center, center);
    }
}

.radio__input {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;

    -webkit-appearance: none;
    outline: 0.1rem solid var(--black);
    outline-offset: 0.4rem;

    &:checked {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background-color: var(--black);
    }
}

@include rmin($bp-tablet-m) {
    .radio__input {
        margin-right: 2rem;
    }
}
