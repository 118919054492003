.breadcrumb {
    &__list {
        display: flex;
        overflow-x: scroll;
    }
    &__list::-webkit-scrollbar {
        display: none;
    }
    &__item {
        display: flex;
        align-items: center;
        color: var(--gray-600);

        &::after {
            content: ">";
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }

        &:last-child {
            pointer-events: none;

            &::after {
                content: none;
            }

            color: var(--black);
        }
    }

    &__link {
        display: flex;
        align-items: center;
        white-space: nowrap;

        color: currentColor;
        font-size: 1.6rem;
        font-family: $font-primary;
        line-height: 1.7rem;

        color: var(--black);
        text-transform: uppercase;
        text-decoration: none;

        &:hover,
        &:active {
            color: var(--gray-400);
            transition: all 0.2s linear;
        }
    }
}

@include rmin($bp-tablet-m) {
    .breadcrumb {
        &__list {
            overflow-x: unset;
            margin-left: unset;
        }
        &__link {
            font-size: 2.3rem;
        }
    }
}
