.registration {
    &__wrap {
        margin: 8.5rem 0 7.5rem;

        & h1 {
            font-size: 2rem;
            font-weight: normal;

            text-align: center;
            text-transform: uppercase;
        }
    }

    &__form {
        margin-top: 3.5rem;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }



        & label,
        & .wa-name {
            position: relative;
            display: block;

            font-size: 1.4rem;
        }

        & label::first-letter,
        & .wa-name::first-letter {
            display: inline-block;
            text-transform: capitalize;
        }

        & input {
            margin-top: 0.5rem;
            padding: 1rem 1rem;

            font-size: 1.6rem;
        }

        & .wa-error-msg {
            display: inline-block;
            margin-top: 0.5rem;

            font-style: normal;
            font-size: 1.4rem;

            color: $danger;
        }
    }

    &__required {
        &::after {
            content: "*";
            position: absolute;
            top: 0.2rem;

            margin-left: 0.3rem;

            font-size: 2.1rem;
            line-height: 1.6rem;

            color: $warning;
        }
    }

    &__btn {
        width: 100%;
        padding: 1.2rem;
        margin-top: 3.5rem;

        font-size: 1.6rem;
        line-height: 1.6rem;
    }

    &__link {
        margin-top: 1.5rem;
        font-size: 1.4rem;
        text-align: center;

        & a {
            margin-left: 0.5rem;
            color: $warning;
            text-decoration: underline;
        }
    }

    &__support {
        display: inherit;
        margin-top: 1rem;

        font-size: 1.4rem;
        text-align: right;
        color: $warning;
        text-decoration: underline;
    }
}

@include rmin($bp-tablet-m) {
    .registration {
        &__wrap {
            margin: 15rem 0 15rem;

            & h1 {
                font-size: 2.2rem;
            }
        }

        &__form {
            max-width: 30rem;
            margin: 3.5rem auto 0;
        }

        &__btn {
            display: inherit;
            max-width: 28rem;
            margin: 3.5rem auto 0;
        }
    }
}
