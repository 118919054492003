.search {
    &__wrap {
        margin: 8.5rem 0 7.5rem;
    }

    &__text-wrapper {
        text-transform: uppercase;

        & h1 {
            margin-top: 2.5rem;

            font-weight: normal;
            font-size: 2.4rem;
        }

        & span {
            display: inline-block;
            margin-top: 2.5rem;

            font-size: 1.6rem;
            color: var(--gray-500);
        }
    }

    &__items {
        justify-content: space-between;

        & .product__item {
            margin-top: 3.5rem;
        }
    }
}

@include rmin($bp-tablet-m) {
    .search {
        &__wrap {
            margin: 10rem 0;
        }

        &__items {
            & .product__item {
                margin-bottom: 1.5rem;
            }
        }

        &__text-wrapper {
            & h1 {
                margin-top: 3.5rem;

                font-size: 4.5rem;
            }

            & span {
                margin-top: 3.5rem;

                font-size: 2.3rem;
            }
        }

        &__btn-inner {
            margin-top: 3.5rem;
        }
    }
}
