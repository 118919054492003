.icon-arr-more {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arr-next {
  width: 1.67em;
  height: 1em;
  fill: initial;
}
.icon-arr-prev {
  width: 1.67em;
  height: 1em;
  fill: initial;
}
.icon-call {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-cart {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 0.94em;
  height: 1em;
  fill: initial;
}
.icon-contact {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-done {
  width: 1.25em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-faq {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-fav-filled {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-fav {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-favorite-filled {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-i-search-2 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-i-star-2 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-i-user-2 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-insta {
  width: 0.96em;
  height: 1em;
  fill: initial;
}
.icon-log-out {
  width: 1.04em;
  height: 1em;
  fill: initial;
}
.icon-logo {
  width: 2.21em;
  height: 1em;
  fill: initial;
}
.icon-mastercard {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-more {
  width: 0.92em;
  height: 1em;
  fill: initial;
}
.icon-next {
  width: 0.6em;
  height: 1em;
  fill: initial;
}
.icon-pen {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-prev {
  width: 0.58em;
  height: 1em;
  fill: initial;
}
.icon-privacy {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-refund {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-ship {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-terms {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-time {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-user {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-user2 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-visa {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-wallet {
  width: 0.89em;
  height: 1em;
  fill: initial;
}
