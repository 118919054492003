.tabs {
    &__main-page {
        margin-bottom: 7.5rem;
    }
    &__titles {
        display: flex;
        justify-content: center;
        margin: 0 auto 1rem;
        overflow-x: scroll;

        cursor: pointer;

        & span {
            white-space: nowrap;

            font-size: 1.8rem;

            text-transform: uppercase;
            color: var(--black);
        }
        & span:not(:last-child) {
            margin-right: 4.3rem;
        }
    }
    &__titles::-webkit-scrollbar {
        display: none;
    }
    &__title--active {
        position: relative;

        &:after {
            content: "";
            display: block;
            height: 0.2rem;
            width: 100%;
            background-color: var(--black);
        }
    }
    &__content {
        display: none;
    }

    &__content--active {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@include rmin($bp-tablet-m) {
    .tabs {
        &__main-page {
            margin-bottom: 15rem;
        }
        &__titles {
            & span {
                font-size: 2.4rem;
            }
        }
    }
}
