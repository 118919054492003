.error {
    &__wrap {
        margin: 8.5rem 0 7.5rem;

        text-align: center;

        & h1 {
            margin-bottom: 1.5rem;

            font-weight: normal;
            font-size: 2.2rem;
            text-transform: uppercase;
        }

        & .product-back__btn-inner {
            margin-top: 3rem;
        }
    }

    &__img {
        margin-bottom: 2rem;
    }

    &__text {
        font-size: 1.8rem;
        color: var(--gray-500);
    }

    &__text a {
        margin: 0 0.4rem 0 0.2rem;
        text-decoration: underline;
        color: var(--black);
    }


}

@include rmin($bp-tablet-m) {
    .error {
        &__wrap {
            width: 65rem;
            margin: 15rem auto;

            & h1 {
                margin-bottom: 1rem;
                font-size: 2.4rem;
            }

            & .product-back__btn-inner {
                margin-top: 3.5rem;
            }
        }

        &__img {
            margin-bottom: 5.7rem;
        }

        &__text {
            font-size: 2.4rem;

            &--sm,
            &--sm a {
                display: inline-block;
                font-size: 2rem;
            }
        }

        &__text a {
            margin: 0 0.4rem 0 0;
        }
    }
}
