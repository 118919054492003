.category {
    &__wrap {
        margin: 8.5rem 0 7.5rem;
    }

    &__desription {
        display: flex;
        justify-content: space-between;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &__filter {
        margin-bottom: 3.6rem;

        label {
            font-size: 1.6rem;
        }

        li {
            margin-bottom: 1.6rem;

            & label {
                cursor: pointer;
            }
        }

        & li:not(:first-child) {
            margin-left: 0.5rem;
            cursor: pointer;
        }

        & span {
            display: inline-block;
            margin-bottom: 2rem;

            font-size: 1.8rem;
            text-transform: uppercase;
        }
    }
    &__breadcrumb-list {
        max-width: 21.4rem;
    }
    ///menu
    &__nav-wrapper {
        transform: translateX(-100%);
        position: fixed;

        width: 100%;
        opacity: 0;

        background-color: var(--white);
        will-change: transform;
        overflow-x: hidden;
        transform: translateX(100%);

        z-index: 1;
        box-shadow: -3.5rem 0 5rem rgba(0, 0, 0, 0.05);
    }

    &__nav-content {
        margin-top: 7.5rem;
    }

    &__menu-toggler:hover,
    &__menu-toggler:focus {
        outline: 0;
    }

    &__menu-toggler {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0;
        padding: 0;

        font-size: 1.6rem;
        font-family: $font-primary;
        line-height: 1.7rem;

        background-color: transparent;
        text-transform: uppercase;
        border: none;
        color: var(--black);

        transition: background-color 0.3s, color 0.3s;
        cursor: pointer;
    }

    &__menu-toggler--opened ~ &__nav-wrapper {
        right: 0;
        bottom: 0;
        opacity: 1;
        overflow: auto;

        height: 100vh;

        padding: 2.5rem;
        transform: translateX(0%);
        transition: transform 0.4s linear;
    }

    &__close {
        display: block;

        position: absolute;
        top: 2.5rem;
        left: 1.8rem;
        z-index: 2;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 2.5rem;
        height: 2.5rem;

        border: 0.1rem solid var(--black);
        background-color: var(--white);
        border-radius: 0.5rem;

        &::after,
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 1rem;

            display: block;
            width: 1.3rem;
            height: 0.1rem;

            background-color: var(--black);
            transform: translateX(-50%);
            transition: all 0.3s;
        }

        &::before {
            width: 1.3rem;
            transform: translateX(-50%) rotate(45deg);
        }

        &::after {
            width: 1.3rem;
            transform: translateX(-50%) rotate(-45deg);
        }
    }

    ///end of menu

    &__btn {
        display: flex;
        justify-content: center;

        width: 100%;
        padding: 1.5rem;
        margin-bottom: 1rem;

        text-transform: uppercase;
    }
}

@include rmin($bp-tablet) {
    .category {
        &__breadcrumb-list {
            max-width: unset;
        }
    }
}

@include rmin($bp-tablet-m) {
    .category {
        &__wrap {
            margin: 10rem 0;
        }

        &__items {
            justify-content: space-between;
        }

        &__filter {
            margin-bottom: 7.5rem;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
        }

        &__filter:nth-child(1) {
            width: 100%;
        }

        &__filter:nth-child(2),
        &__filter:nth-child(3) {
            width: 50%;
        }

        &__filter span {
            font-size: 2rem;
        }

        &__filter label {
            font-size: 2rem;
        }

        &__btns {
            display: flex;
        }

        &__btn {
            display: unset;

            width: unset;
            min-width: 20rem;
            padding: 1.8rem;

            margin-right: 2.5rem;
            margin-bottom: unset;
            font-size: 2rem;

            &:nth-child(2) {
                order: -1;
                min-width: 25rem;
            }
        }

        //menu

        &__menu-toggler {
            z-index: 9999;
            font-size: 2.3rem;
        }

        &__menu-toggler--opened ~ &__nav-wrapper {
            max-width: 650px;
            padding: unset;
        }

        &__nav {
            margin-top: 65px;
            padding: unset;
            border-top: var(--b-1-b-400);
        }

        &__nav-wrapper {
            z-index: 9999;
        }

        &__nav-content {
            margin-top: unset;
            padding: 5rem;
        }

        &__nav-content {
            display: flex;
            flex-direction: column;

            margin-top: 7.5rem;
        }

        &__menu-toggler--opened ~ &__backdrop {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100vh;
            background: rgba(52, 58, 64, 0.5);
        }

        //end of menu
        &__close {
            width: 3.5rem;
            height: 3.5rem;
            margin-left: 3rem;

            &::before,
            &::after {
                top: 1.5rem;
                width: 2.2rem;
            }
        }
    }
}
