.s {
    &-layout {
        input[type="text"],
        input[type="search"],
        input[type="password"],
        input[type="email"],
        input[type="url"],
        input[type="date"],
        input[type="phone"],
        input[type="number"],
        select,
        textarea {
            width: 100%;
            padding: 1.2rem;

            font-size: 1.6rem;
            font-family: $font-primary;
            border: 0.1rem solid var(--gray-500);
            border-radius: 0.5rem;

            &::placeholder {
                font-size: 1.6rem;
                color: var(--gray-400);
            }

            &:focus {
                border: 0.1rem solid var(--black);
            }
        }
    }

    &-layout {
        .wa-address input[type="text"],
        .wa-address input[type="search"],
        .wa-address input[type="password"],
        .wa-address input[type="email"],
        .wa-address input[type="url"],
        .wa-address input[type="date"],
        .wa-address input[type="phone"],
        .wa-address input[type="number"],
        .wa-field-address-street input[type="text"],
        .wa-field-address-city input[type="text"],
        .wa-field-address-zip input[type="text"],
        select,
        textarea {
            border: 0.1rem solid var(--gray-500);

            &:focus {
                border: 0.1rem solid var(--black);
            }
        }
    }

    &-layout > &-column.middle {
        vertical-align: middle;
    }

    &-layout > &-column.right {
        text-align: right;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper {
        & header {
            padding: 1.8rem 1.8rem;
        }

        & .s-header--sm {
            padding: 1.2rem 1.8rem;
            line-height: 1.85rem;
        }

        border-radius: 1rem;
        border: 0.1rem solid var(--gray-400);
        overflow: hidden;

        &:not(:first-child) {
            margin-top: 1.5rem;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header {
        & h5 {
            display: flex;
            font-weight: normal;
            font-size: 1.8rem;
        }

        & .s-name--full {
            margin-left: 1rem;

            & a {
                margin-left: unset;
            }
        }

        & a {
            margin-right: 0.5rem;
            margin-left: 1rem;

            font-size: 1.8rem;
            font-weight: normal;
            color: $primary;
        }

        & a span {
            margin-left: 1rem;
            color: var(--black) !important;
        }

        & a span span {
            margin-left: unset;
        }

        & svg {
            fill: $warning;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-content-wrapper {
        padding: 2.5rem 1.5rem 3.5rem;
        background-color: var(--gray-100);
    }

    ///form
    &-checkout-page &-steps-wrapper .wa-form .wa-field {
        margin-bottom: 1.5rem;

        font-size: 1.6rem;

        & .wa-name {
            margin-bottom: 0.5rem;
            font-weight: normal;

            &::first-letter {
                display: inline-block;
                text-transform: capitalize;
            }
        }
    }

    &-checkout-page &-steps-wrapper .wa-form {
        & h3 {
            margin-bottom: 2.5rem;
            font-weight: normal;
            font-size: 2rem;
            text-transform: uppercase;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper .wa-field.wa-field-address {
        margin-top: 3.5rem;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper .field {
        display: inline-block;
        width: 100%;
        margin-bottom: 1.5rem;

        & input {
            margin-top: 0.5rem;
        }

        & select {
            -webkit-appearance: none;
            margin-top: 0.5rem;

            color: var(--gray-400);
            background: var(--white) url("../img/dropdown.svg") no-repeat 95%;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper .service-agreement-wrapper {
        display: inline-block;
        margin-top: 1.5rem;

        & a {
            margin-left: 0.5rem;
            color: $primary;
        }

        & label input {
            margin-right: 0.5rem;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header h5 span {
        margin-left: 1rem;
    }

    //info-block

    &-checkout-page &-steps-wrapper &-step-wrapper &-column {
        display: flex;
        flex-direction: column;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-column-info {
        margin-top: 5rem;
    }

    &-checkout-page
        &-steps-wrapper
        &-step-wrapper
        &-step-content-wrapper
        &-step-content
        &-contact-form
        &-column-info
        &-info-block {
        height: 15.1rem;
        padding: 0.5rem 2.5rem 0;
        margin-bottom: 1.5rem;

        border: 0.1rem solid var(--gray-400);
        border-radius: 1rem;
    }

    &-checkout-page
        &-steps-wrapper
        &-step-wrapper
        &-step-content-wrapper
        &-step-content
        &-contact-form
        &-column-info
        &__box {
        padding: 2.8rem 2.5rem;
    }

    &-checkout-page
        &-steps-wrapper
        &-step-wrapper
        &-step-content-wrapper
        &-step-content
        &-contact-form
        &-column-info
        &-info-block
        &-info-header {
        display: flex;
        align-items: center;
        padding: unset;
        margin-bottom: 1rem;

        font-size: 1.8rem;

        & img {
            margin-right: 1rem;
        }
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header {
        display: flex;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header &-layout {
        display: flex;
        justify-content: space-between;
    }

    &-checkout-page
        &-steps-wrapper
        &-step-wrapper
        &-step-header
        &-layout
        &-column {
        margin-bottom: unset;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-header &-column {
        display: flex;
        justify-content: center;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-footer {
        padding: 1.1rem 1.8rem;
        background-color: var(--white);
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-submit {
        display: flex;
        align-items: center;

        padding: 1rem 3.65rem;

        font-size: 1.6rem;
        line-height: 1.3;
        text-transform: uppercase;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper &-step-submit &-footer__arr {
        width: 2rem;
        height: 1rem;

        margin-left: 1rem;
        fill: var(--white);
    }

    //is-next

    &-checkout-page &-steps-wrapper &-step-wrapper.is-next {
        font-size: 1.8rem;
    }

    &-checkout-page &-steps-wrapper &-step-wrapper.is-next &-step-header h5 {
        color: var(--gray-500);

        & span {
            display: inline-block;
            margin-left: 1rem;
        }
    }

    &-checkout-page &-steps-wrapper &-step-header h5 span,
    &-checkout-page &-steps-wrapper &-step-header h5 a {
        display: inline-block;

        &::first-letter {
            text-transform: capitalize;
        }
    }

    ///step 3

    &-checkout-page &-shipping-name {
        margin-left: 1rem;
        font-weight: normal;
        font-size: 1.8rem;
    }
    &__ship-added &-shipping-name {
        margin-left: 3rem;
        margin-top: 1rem;
    }
    &-checkout-page label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        margin-bottom: 1.1rem;
    }

    &-checkout-page &-shipping-input {
        width: 2rem;
        height: 2rem;
    }

    &-checkout-page &-rate-wrapper {
        margin-left: 3rem;

        font-size: 1.8rem;

        & .ruble {
            margin-left: 0.4rem;
        }
    }

    &-checkout-page &-shipping-image {
        margin-left: 1rem;
    }

    &-checkout-page &-shipping-description {
        font-size: 1.6rem;

        & a {
            color: $primary;
        }
    }

    &-checkout-page .shipping-10 {
        position: relative;

        & .s-shipping-details {
            margin-top: 5.8rem;
        }
    }

    &-checkout-page &-shipping-item:nth-of-type(2) &-rate-wrapper {
        position: absolute;
        top: 6rem;
    }

    //step 4
    &__hidden {
        display: none !important;
    }

    &-checkout-page &-ship__wrapper {
        margin-bottom: 3.5rem;
    }

    &-rate__sec {
        position: unset !important;

        & .s-delivery-wrapper {
            margin-top: 1rem;
            font-size: 1.6rem;
        }

        & .s-delivery {
            display: block;
            margin-top: 0.3rem;
        }
    }

    &-ship__sec {
        margin-top: unset !important;
        margin-left: 3.2rem;

        & .s-shipping-description {
            margin-bottom: 1.5rem;
        }
    }

    &-checkout-page &-payment-options &-payment-item {
        &:not(:last-child) {
            margin-bottom: 2.5rem;
        }

        & span {
            display: block;
            width: 100%;

            margin-top: 1rem;
            margin-left: 3.5rem;

            font-weight: normal;
            font-size: 2.2rem;
        }

        & .s-payment-input {
            width: 2rem;
            height: 2rem;
        }

        & .s-payment-description {
            margin-left: 3.5rem;
            margin-top: -1rem;

            font-size: 1.8rem;
            color: var(--gray-500);

            & a {
                color: $primary;
            }
        }

        & .s-payment__img-wrap {
            width: 10rem;
            height: 3.5rem;

            margin-left: 1rem;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                border: 0.1rem solid var(--gray-400);
                border-radius: 0.5rem;
                overflow: hidden;
            }
        }
    }

    //step 5
    &__final-step {
        padding: 3.5rem 1.5rem !important;

        & .s-contact-header {
            margin-bottom: 2.5rem;

            text-transform: uppercase;
            font-size: 2rem;
        }

        & .s-userpic {
            width: 3.5rem;
            height: 3.5rem;

            border-radius: 50%;
            object-fit: cover;
            object-position: top;
            overflow: hidden;
        }

        & .s-user-name {
            display: block;
            margin-top: 0.5rem;

            font-weight: normal;
            font-size: 1.8rem;
        }

        & .s-address-wrapper {
            margin-top: 1rem;
            margin-bottom: 2.5rem;
            font-size: 1.8rem;
        }

        & thead .s-column-name,
        & thead .s-column-amount,
        & thead .s-column-total {
            display: none;
        }

        & .s__final-item {
            position: relative;
            display: inline-block;
            width: 100%;
            padding: 1.5rem 1rem;

            background-color: var(--white);
            border-radius: 0.5rem;

            & .s-name {
                font-size: 1.8rem;
            }

            & .s-value {
                font-size: 1.8rem;
                color: var(--gray-500);
            }

            & .s-value .bold {
                margin-left: 1rem;
                color: var(--black);
            }

            & .s-total {
                font-size: 1.8rem;
            }

            & .s-column-name {
                display: inline-block;
                width: 100%;

                margin-bottom: 2.5rem;
            }

            & .s-column-amount,
            & .s-column-total {
                display: inline-block;
            }

            & .s-column-amount {
                min-width: 9.2rem;
                margin-right: 2.5rem;
            }

            & .s-column-total {
                position: absolute;
                right: 1.5rem;

                width: 15rem;
                text-align: right;
            }
        }

        & .s-total-wrapper {
            margin-top: 2.5rem;
            font-size: 1.8rem;

            & tbody tr:nth-of-type(1) {
                display: inline-block;
                margin-bottom: 1.5rem;
            }

            & tbody tr:nth-of-type(2) {
                display: inline-block;
                margin-bottom: 2.5rem;
            }
        }

        & .s-total-wrapper .column-1 {
            color: var(--gray-500);
            text-align: left;

            & strong {
                margin-left: 0.5rem;
                font-weight: normal;
                color: var(--black);
            }
        }

        & .s-total-wrapper .column-1,
        & .s-total-wrapper .column-2 {
            display: inline-block;
            font-weight: normal;
        }

        & .s-total-wrapper .column-2 {
            width: 100%;
        }

        & .s-total-wrapper .s-total-sum {
            display: inline-block;
            margin-bottom: 2.5rem;
            font-size: 2rem;
        }
    }

    //success page
    &__check-success {
        & .s-step-header {
            padding: 1.7rem 2rem !important;
        }

        & h2 {
            font-size: 1.6rem;
            font-weight: normal;
        }

        h4 {
            display: none;
        }

        & .s-text-section {
            margin-top: 3.5rem;
            font-size: 1.8rem;
        }

        & .s-order-number {
            display: block;
            margin-top: 0.5rem;

            color: $primary;
        }

        & .s-step-content-wrapper {
            padding: 5rem 1.5rem 3.5rem !important;
        }

        & .s-success-back {
            display: inline-block;
            margin-top: 3.5rem;
            padding: 0.9rem 1rem;

            font-size: 1.6rem;
            color: currentColor;

            & span {
                margin-left: 0.5rem;
            }

            & svg {
                width: 2rem;
                height: 1rem;
            }
        }
    }
}

@include rmin($bp-phone-s) {
    .s {
        &__ship-added &-shipping-name {
            margin-left: 1rem;
            margin-top: 0.5rem;
        }
        &-checkout-page &-shipping-item:nth-of-type(2) &-rate-wrapper {
            top: 3.2rem;
        }
    }
}
@include rmin($bp-tablet-m) {
    .s {
        &__number {
            padding-left: 5rem !important;
        }
        &-checkout-page &-steps-wrapper &-step-wrapper &-step-header {
            a {
                font-size: 2.6rem;
            }
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-header h5 {
            font-size: 2.6rem;
        }

        &-checkout-page &-steps-wrapper .wa-form h3 {
            width: 100%;
            margin-bottom: 3.5rem;
            font-size: 2.2rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper header {
            // height: 7rem;
            padding: 1.7rem 5rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper .s-header--sm {
            padding: 1.7rem 5rem;
            line-height: 2.6rem;

            min-height: 7rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-content-wrapper {
            padding: 5rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-footer {
            padding: 1.6rem 5rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-submit {
            justify-content: center;

            min-width: 20rem;
            padding: 1.6rem;
            line-height: 1;
        }

        &-checkout-page &-steps-wrapper .wa-form .wa-field .wa-name {
            margin-bottom: 1rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper:not(:first-child) {
            margin-top: 2.5rem;
        }

        &-layout &-checkout-page {
            input[type="text"],
            input[type="search"],
            input[type="password"],
            input[type="email"],
            input[type="url"],
            input[type="date"],
            input[type="phone"],
            input[type="number"],
            select {
                width: 50%;
                min-width: 45rem;

                padding: 1.4rem 2rem;
            }
        }

        &-layout &-checkout-page .wa-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-checkout-page &-steps-wrapper .wa-form .wa-field {
            width: 48%;
        }

        &-checkout-page &-steps-wrapper .wa-form .wa-field {
            margin-bottom: 3.5rem;
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            .service-agreement-wrapper {
            width: 100% !important;
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            .wa-field.wa-field-address {
            margin-top: 1.5rem;
            margin-bottom: unset;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper .field input {
            margin-top: 1rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper .field {
            display: block;
            margin-bottom: 3.5rem;
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            .wa-field.wa-field-address {
            width: 100%;

            & p {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            & p span {
                display: block;
                width: 48%;
            }
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            &-step-content-wrapper
            &-step-content
            &-contact-form
            &-column-info
            &-info-block {
            max-width: 27.5rem;

            margin-right: 2rem;
            margin-bottom: 0;

            padding: 0 2.5rem;
        }

        &-checkout-page
            &-steps-wrapper
            &-step-wrapper
            &-step-content-wrapper
            &-step-content
            &-contact-form
            &-column-info
            &__box {
            padding: 2.8rem 2.5rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-column-info {
            flex-direction: row;

            margin-top: 1.5rem;
        }

        &-checkout-page &-steps-wrapper &-step-wrapper &-step-header svg {
            width: 2.5rem;
            height: 2rem;
        }

        ///step 3

        &-checkout-page &-shipping-name {
            font-size: 2.2rem;
        }

        &-checkout-page &-ship__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 2rem;

            & .s-column:nth-child(2) {
                width: 50%;

                & .s-rate-wrapper {
                    margin-left: 1rem;
                }
            }
        }

        &-checkout-page &-steps-wrapper &-step-wrapper .field select {
            margin-top: 1rem;
        }

        &-checkout-page label {
            margin-bottom: unset;
        }

        &-checkout-page .shipping-10 &-shipping-details {
            margin-top: 2.6rem;
        }

        &-checkout-page .shipping-10 &-layout {
            display: flex;
            flex-wrap: wrap;
        }

        &-checkout-page .shipping-10 &-column:nth-child(1),
        &-checkout-page .shipping-10 &-column:nth-child(2) {
            width: 50%;
        }

        &-checkout-page &-shipping-item:nth-of-type(2) &-rate-wrapper {
            position: unset;
        }

        &-checkout-page &-rate-wrapper {
            font-size: 2.2rem;
        }

        //step 4

        &-rate__sec {
            position: unset !important;
            margin-left: 1rem !important;
        }

        &-ship__sec {
            max-width: 47.7rem;

            margin-left: unset;
            margin-bottom: 1rem;

            & .s-shipping-description {
                margin-bottom: unset;
                margin-top: 1.2rem;
            }
        }

        &__ship-selected {
            margin-top: 5rem;
        }

        &-checkout-page &-payment-options &-payment-item {
            &:not(:last-child) {
                margin-bottom: 5rem;
            }

            & span {
                width: unset;
                margin-top: unset;
                margin-left: 1rem;

                font-size: 2.2rem;
            }

            & .s-payment-description {
                margin-top: 0.5rem;
                margin-left: unset;
            }
        }

        //step 5
        &__final-step {
            padding: 5rem !important;

            & thead .s-column-name,
            & thead .s-column-amount,
            & thead .s-column-total {
                display: inline-block;

                font-size: 2rem;
                font-weight: normal;
                color: var(--gray-500);
            }

            & thead tr {
                display: flex;
                justify-content: flex-end;

                margin-bottom: 1.5rem;
            }

            & thead .s-column-total,
            & thead .s-column-amount {
                width: 15rem;
            }

            & thead .s-column-total {
                text-align: right;
            }

            & thead .s-column-amount {
                margin-right: 5rem;
            }

            & .s-contact-header {
                margin-bottom: 3.5rem;
                font-size: 2.2rem;
            }

            & .s-user-name {
                margin-top: 1rem;
            }

            & .s-user-name {
                font-size: 2rem;
            }

            & .s-address-wrapper {
                margin-bottom: 3.5rem;
                font-size: 2rem;
            }

            & .s__final-item {
                position: unset;
                display: block;
                margin-bottom: 0.5rem;

                & tr {
                    display: flex;
                }
            }

            & .s__final-item .s-name {
                font-size: 2rem;
            }

            & .s__final-item .s-value,
            & .s__final-item .s-total {
                font-size: 2rem;
            }

            & .s__final-item .s-column-amount {
                min-width: 15rem;
                margin-left: auto;

                text-align: center;
            }

            & .s__final-item .s-column-name {
                width: unset;
                margin-bottom: unset;
            }

            & .s__final-item .s-column-total {
                position: unset;
            }

            & .s-total-wrapper tr {
                display: flex !important;
                width: 100%;
                text-align: right;

                font-size: 2rem;
            }

            & .s-total-wrapper .column-1 {
                margin-left: auto;
                margin-right: 5rem;
            }

            & .s-total-wrapper .column-2 {
                width: 15rem;
            }

            & .s-total-wrapper tbody tr:nth-of-type(1) {
                margin-bottom: 1rem;
            }

            & .s-total-wrapper tbody tr:nth-of-type(2) {
                margin-bottom: 3.5rem;
            }

            & .s-total-wrapper .s-total-sum {
                margin-bottom: 7.5rem;
                font-size: 2.4rem;
            }

            & .s-total-wrapper {
                margin-top: 3.5rem;
            }
        }

        //success page
        &__check-success {
            & .s-step-header {
                padding: 2.7rem 5rem !important;
            }

            & h2 {
                font-size: 2.6rem;
            }

            & h4 {
                display: block;
                margin-bottom: 4.7rem;

                font-size: 2.2rem;
                font-weight: normal;
                text-transform: uppercase;
            }

            & .s-step-content-wrapper {
                padding: 5rem !important;
            }

            & .s-text-section {
                font-size: 2rem;

                & a {
                    display: inline-block;
                    margin-left: 1rem;
                }
            }

            & .s-success-back {
                display: inline-flex;
                align-items: center;

                margin-top: 3.5rem;
                padding: 1.55rem 2rem !important;

                font-size: 1.6rem;
                color: currentColor;

                & svg {
                    width: 2rem;
                    height: 1rem;
                }

                & span {
                    margin-left: 1rem;
                }
            }
        }
    }
}
