.s {
    &-layout {
        display: table;
        width: 100%;
    }

    &-content-block {
        position: relative;
        min-height: 22.3rem;

        margin: 8.5rem 0 7.5rem;
    }

    &__product-inner {
        position: relative;
    }
    &-cart-page &-empty-section {
        border: 0.1rem solid var(--gray-400);
        border-radius: 1rem;
        overflow: hidden;
    }

    &-cart-page &-empty-section > &-section-header {
        padding: 1.7rem 2rem;
        background-color: var(--white);
    }

    &-cart-page &-empty-section > &-section-header h2 {
        font-size: 1.6rem;
        font-weight: normal;
        text-transform: uppercase;
    }

    &-cart-page &-empty-section > &-section-body {
        padding: 3.5rem 1.5rem;
        background-color: var(--gray-100);
    }

    &-cart-page &-empty-section > &-section-body p {
        display: block;
        margin-bottom: 1rem;
        margin-top: 3.5rem;
        font-size: 1.8rem;
    }

    &-cart-page &-empty-section > &-section-body span {
        display: block;
        font-size: 1.6rem;
        color: var(--gray-500);
    }

    &-cart-page &-empty-section > &-section-footer {
        display: block;
        padding: 0 1.5rem 3.5rem;
        background-color: var(--gray-100);
    }

    &-cart-page &-empty-section > &-section-footer &-link-back {
        display: inline-flex;
        align-items: center;
        padding: 1rem;

        font-size: 1.4rem;
        line-height: 1.3;
        color: var(--black);
        background-color: transparent;
    }

    &-cart-page &-empty-section > &-section-footer &-link__ico {
        width: 2rem;
        height: 1rem;

        margin-right: 1rem;
    }

    /////// cart
    &-cart-product {
        padding: 3.5rem 0;
        border-bottom: 0.1rem solid var(--black);

        &:first-child {
            border-top: 0.1rem solid var(--black);
        }
    }
    //product

    &__product-item {
        display: flex;

        & .s__product-text {
            width: 100%;
        }
    }
    &-product-image {
        display: block;
    }
    &-product__img-wrapper {
        width: 6.5rem;
        height: 6.5rem;
        background-color: var(--gray-100);

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    //title
    &-texs-column {
        display: flex;
        flex-direction: column;

        margin-left: 1.5rem;
        text-transform: uppercase;
        color: var(--black);

        & .s-sku-wrapper {
            margin-bottom: -1.7rem;
            font-size: 1.2rem;

            & .s-sku {
                margin-left: 0.3rem;
            }
        }
    }
    &-product-name {
        margin-bottom: 1rem;

        font-weight: normal;
        font-size: 1.4rem;

        & a {
            color: currentColor;
        }
    }

    //delete btn
    &-actions-column {
        display: flex;
        align-items: flex-end;
        max-width: 4.9rem;

        margin-left: auto;
        margin-bottom: 3rem;
    }
    &-delete-button {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: var(--gray-400);
    }
    //counter
    &__product-counter {
        display: flex;
        align-items: center;
        max-height: 3.3rem;
        margin-top: 1.5rem;
        margin-left: 6.7rem;

        & .s-quantity-wrapper {
            position: relative;

            max-width: 6.7rem;
            padding: 0 !important;
        }

        & .s-amount-column {
            position: absolute;
            top: 8rem;
            left: 0;
        }

        .s-product-quantity {
            padding: 0.85rem 1rem !important;

            font-size: 1.4rem !important;
            text-align: center;
        }

        & .js-decrease,
        & .js-increase {
            position: absolute;
            top: 0;

            width: 2rem;
            height: 100%;

            padding: 0 !important;
            background: transparent;
        }

        & .plus {
            right: 0;
        }
        & .minus {
            left: 0;
        }

        ///prices
        & .right span {
            display: inline-block;
            font-size: 1.2rem;

            &::first-letter {
                text-transform: capitalize;
            }
        }
        & .s-product-total span,
        & .s-product-price span {
            margin-left: 0.3rem;
            font-size: 1.6rem;
        }

        & .s-old__price .s-product__old {
            display: flex;

            margin-left: 1rem;
            margin-top: 2.2rem;
            font-size: 1.6rem;
            text-decoration: line-through;
            color: var(--gray-400);

            & span {
                display: block !important;
                font-size: 1.6rem;
            }
        }
        & .s-old__price span:nth-of-type(1) {
            display: none;
        }

        & .s-total-column {
            order: 1;
            margin-left: auto;

            text-align: right;
        }
        & .s-price-column {
            text-align: left;
            margin-left: 1.5rem;
        }
    }
    //total price

    &-cart-page &-cart-total &-table-section {
        display: flex;
        justify-content: flex-end;

        margin-top: 3.5rem;
    }

    &-cart-page &-cart-total &-table-section &-totals-table &-total-wrapper {
        display: inline-table;
        margin-bottom: 1.5rem;
    }

    &-cart-page
        &-cart-total
        &-table-section
        &-totals-table
        &-total-wrapper
        td
        span {
        text-transform: uppercase;
        font-size: 1.2rem;
    }

    &-cart-page
        &-cart-total
        &-table-section
        &-totals-table
        &-total-wrapper
        &-column-price
        span {
        display: inline-block;
        margin-left: 0.5rem;
        font-size: 2.2rem;
    }

    &-cart-page &-cart-total &-table-section &-totals-table &-cart-checkout {
        display: flex;
        justify-content: flex-end;
    }

    &-cart-page
        &-cart-total
        &-table-section
        &-totals-table
        &-cart-checkout
        &-submit-button {
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    &-cart-page
        &-cart-total
        &-table-section
        &-totals-table
        &-cart-checkout
        &-btn-arr {
        width: 2rem;
        height: 1rem;
        margin-left: 1rem;
        fill: var(--white);
    }

    //return btn
    &-content-block &-cart__return {
        margin-bottom: 3.5rem;
    }

    &-content-block &-cart__return span:nth-child(1) {
        display: block;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }

    &-content-block &-cart__return &-cart__return-btn {
        display: inline-flex;
        align-items: center;
        padding: 1rem;

        font-size: 1.4rem;
        line-height: 1.3;
        color: var(--black);
        background-color: transparent;
    }

    &-content-block &-cart__return &-cart__arr {
        margin-right: 1rem;
    }

    //before card
    &-content-block &-cart-page &-sup__table {
        display: none;
    }
}

@include rmin($bp-tablet-m) {
    .s {
        //before cart
        &-content-block &-cart-page &-sup__table {
            display: table;

            // max-width: 53.4rem;
            width: 48%;
            margin-bottom: 2.5rem;

            margin-left: auto;

            & span {
                display: inline-block;
                font-size: 1.4rem;
            }

            & span::first-letter {
                text-transform: capitalize;
            }
            & td {
                width: 30%;
                text-align: center;
            }
            & td:nth-of-type(1) {
                text-align: left;
                // width: 45%;
            }
            & td:nth-of-type(2) {
                text-align: center;
            }

            & td:last-child {
                // width: 23%;
                text-align: right;
            }

            & td:nth-of-type(3) span {
                margin-right: 2rem;
            }
        }

        &-cart-page &-empty-section > &-section-footer &-link-back {
            padding: 1.3rem 1.9rem;
            font-size: 1.6rem;
        }

        &-cart-page &-empty-section > &-section-header h2 {
            font-size: 2.6rem;
        }
        &-cart-page &-empty-section > &-section-body p {
            font-size: 2.6rem;
        }

        &-cart-page &-empty-section > &-section-body span {
            font-size: 2rem;
        }
        &-empty-section {
            & .s-section-header {
                padding: 2.7rem 5rem !important;
            }

            & .s-section-body {
                padding: 5rem 5rem 3.5rem !important;
            }

            & .s-section-footer {
                padding: 0 0 8rem 5rem !important;
            }
        }
        ///cart
        //product

        &__product-inner {
            position: unset;
            display: flex;
            justify-content: space-between;
        }
        &-product__img-wrapper {
            width: 11rem;
            height: 11rem;
        }
        //total price

        &-cart-page &-cart-total &-table-section {
            margin-top: 5rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper {
            margin-bottom: 2.5rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper
            &-column-price
            span {
            font-size: 2.8rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper
            &-column-price
            span:not(:last-child) {
            margin-left: 1.7rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-cart-checkout
            &-submit-button {
            display: inline-flex;
            justify-content: center;
            min-width: 20rem;
            font-size: 1.6rem;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &-cart-page
            &-cart-total
            &-table-section
            &-totals-table
            &-total-wrapper
            td
            span {
            line-height: 1.2;
        }

        //title
        &-texs-column {
            max-width: 40rem;
            & .s-sku-wrapper {
                margin-bottom: 1.5rem;

                font-size: 1.6rem;
            }
        }
        &-product-name {
            margin-bottom: 1.5rem;
            font-size: 1.8rem;
        }
        //delete btn
        &-actions-column {
            display: flex;
            align-items: flex-end;
            margin-left: 1.5rem;
            margin-bottom: unset;
        }
        &-delete-button {
            font-size: 1.4rem;
        }
        //counter

        &__product-counter {
            position: relative;
            width: 50%;

            margin-top: unset;
            margin-left: unset;
            font-size: 2.2rem;

            & .s-total-column span,
            & .s-price-column span {
                display: none;
                font-size: 2.2rem;
            }
            & .s-amount-column {
                position: unset;
                margin-left: auto;
            }

            & .s-total-column .s-product-total span,
            & .s-price-column .s-product-price span {
                display: inline-block !important;
            }

            & .s-price-column {
                order: -1;
            }
            & .s-old__price {
                position: absolute;
                left: 0.7rem;
                top: 0.5rem;

                & .s-product__old {
                    font-size: 1.9rem;

                    & span {
                        font-size: 1.9rem;
                    }
                }
            }
            & .s-quantity-wrapper {
                max-width: 9.4rem;
            }

            & .s-product-quantity {
                font-size: 1.8rem !important;
            }

            & .js-decrease,
            & .js-increase {
                width: 3rem;
            }
        }

        //return btn
        &-content-block &-cart__return {
            margin-bottom: 5rem;
        }

        &-content-block &-cart__return span:nth-child(1) {
            margin-bottom: 2rem;
            font-size: 2.4rem;
        }

        &-content-block &-cart__return &-cart__return-btn {
            padding: 1.3rem 1.6rem;
            font-size: 1.6rem;
        }
    }
}
