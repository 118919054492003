.shipping {
    &__wrap {
        margin: 8.5rem 0 7.5rem;

        & h1 {
            font-size: 2.4rem;
            font-weight: normal;

            text-transform: uppercase;
            text-align: center;
        }
    }

    //tabs
    &__tabs {
        text-align: center;
    }

    &__tab-content {
        display: none;

        &--active {
            display: flex;
            flex-direction: column;
        }
    }

    &__tab-items {
        display: flex;

        margin: 3.5rem 0 1.5rem;

        li {
            width: 20%;
        }
    }

    &__tab-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        color: var(--gray-500);
        transition: color 0.3s linear;

        & svg {
            width: 2.5rem;
            height: 2rem;
            fill: var(--gray-500);
            transition: fill 0.3s linear;
        }

        & span {
            display: inline-block;
            margin-top: 0.5rem;
            font-size: 0.8rem;
            text-transform: uppercase;
        }

        &--active {
            color: var(--black);
        }

        &--active svg {
            fill: var(--black);
        }

        &:hover {
            cursor: pointer;
            color: var(--black);
        }

        &:hover svg {
            fill: var(--black);
        }
    }

    &__tab-list {
        text-align: left;
        font-size: 1.6rem;

        & p {
            margin-top: 2rem;
        }
    }

    &__tab-content {
        margin: 0 -2rem;
        padding: 1.5rem;
        background-color: var(--gray-50);
    }

    &__tab-link {
        color: $warning;

        &--underlined {
            margin: 0 0.5rem 0 0;
            text-decoration: underline;
        }
    }

    &__inner-list {
        margin-top: 2rem;

        & li {
            display: flex;
            align-items: center;
        }

        & li::before {
            content: "";
            display: inline-block;

            width: 0.5rem;
            height: 0.5rem;
            margin-right: 1rem;

            font-weight: bold;
            background-color: $warning;
        }
    }

    &__inner-list--decimal {
        counter-reset: item;
        margin-top: 2rem;

        & li::before {
            counter-increment: item;
            content: counter(item) ") ";

            margin-right: 0.5rem;
            color: $warning;
        }
    }

    //card
    &__card-text {
        display: flex;
        flex-direction: column;
        text-align: center;

        h2 {
            margin-top: 2rem;

            font-size: 2.4rem;
            font-weight: normal;
        }

        span {
            display: block;
            margin-top: 0.5rem;

            font-size: 1.6rem;
        }
    }

    &__img-wrap {
        display: flex;
        justify-content: center;
    }

    &__button {
        display: inline-flex;
        justify-content: center;

        min-width: 20rem;
        padding: 1.2rem;
        margin: 2rem auto 0;

        font-size: 1.6rem;
        text-transform: uppercase;
    }

    //loyality

    &__loyality {
        span {
            display: inline-block;
        }

        & span:nth-child(1) {
            margin-top: 3.5rem;
            font-size: 1.8rem;
            text-transform: uppercase;
        }

        & span:last-child {
            margin-top: 0.5rem;
        }

        ul {
            margin-top: 1.5rem;
        }

        li {
            display: flex;
            align-items: center;

            margin-bottom: 1rem;
            font-size: 1.6rem;
            line-height: 1.6rem;
        }

        li:before {
            content: url(../icons/wallet.svg);
            margin-right: 1.3rem;
        }
    }

    //rules
    &__rules {
        margin-top: 3.5rem;

        ul {
            margin-top: 1.5rem;
        }

        li {
            display: flex;
            align-items: flex-start;
            line-height: 1.6rem;
            font-size: 1.6rem;
        }

        li:not(:last-child) {
            margin-bottom: 1rem;
        }

        li span {
            min-width: 3rem;
            margin-right: 0.5rem;
            color: $warning;
        }

        h5 {
            margin-bottom: 1.5rem;

            font-weight: normal;
            font-size: 1.8rem;
            text-transform: uppercase;
        }
    }

    //contacts

    &__contacts h1,
    &__form h1 {
        margin-bottom: 2.5rem;
        font-size: 2rem;
    }

    &__contacts {
        margin-bottom: 3.5rem;
    }

    &__contacts-item {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        & svg {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;

            fill: $warning;
        }

        & span {
            line-height: 1.6rem;
        }
    }

    //form
    &__form {
        label {
            font-size: 1.6rem;
        }

        input,
        textarea {
            margin-top: 1rem;
            padding: 1.5rem 2rem;

            font-family: $font-primary;
            font-size: 1.6rem;
        }

        input::placeholder,
        textarea::placeholder {
            color: var(--gray-400);
        }

        button {
            margin-top: 1rem;
            text-transform: uppercase;
        }
    }
}

@include rmin($bp-tablet-m) {
    .shipping {
        &__wrap {
            margin: 10rem 0;

            & h1 {
                text-align: left;
            }
        }

        //tabs
        &__tabs {
            display: flex;
            justify-content: space-between;
            text-align: left;

            margin-top: 3.5rem;
        }

        &__tab-items {
            flex-direction: column;
            margin-bottom: unset;

            margin-right: 2.5rem;
            margin-top: 1.5rem;

            li {
                width: 30rem;
            }

            li:not(:last-child) {
                border-bottom: 0.1rem solid var(--gray-400);
            }
        }

        &__tab-list {
            width: 70%;
        }

        &__tab-item {
            position: relative;
            flex-direction: row;
            width: unset;

            padding: 1.5rem 0;

            & span {
                margin-top: unset;
                margin-left: 1rem;
                font-size: 1.8rem;
            }

            & svg {
                height: 2.5rem;
            }
        }

        &__tab-item--active {
            &::after {
                content: url(../icons/next.svg);
                position: absolute;
                right: 0;
            }
        }

        &__tab-content {
            margin: unset;
            padding: 2.5rem;
        }

        &__tab-list {
            font-size: 1.8rem;
        }

        //card {
        &__card {
            display: flex;
            align-items: center;
        }

        &__card-text {
            width: 50%;

            & h2 {
                margin-top: unset;
                font-size: 3rem;
            }

            & span {
                font-size: 2rem;
            }
        }

        &__img-wrap {
            width: 50%;
        }

        //loyality
        &__loyality {
            ul {
                margin-top: 2rem;
            }

            li {
                margin-bottom: 1.2rem;
                font-size: 1.8rem;
            }

            & span:nth-child(1) {
                font-size: 2rem;
            }
        }

        &__rules {
            margin-top: 5rem;

            & h5 {
                margin-bottom: 2rem;
                font-size: 2rem;
            }

            & li {
                font-size: 1.8rem;
            }
        }

        //form

        &__contacts-item,
        &__contacts {
            span {
                font-size: 1.6rem;
            }
        }

        &__form {
            margin-top: 1.5rem;

            & input {
                width: 45rem;
            }

            & button {
                margin: unset;
                max-width: 20rem;
                margin-top: 2.5rem;
            }
        }
    }
}
