.personal-area {
    &__wrap {
        margin: 8.5rem 0 7.5rem;

        & .wa-field-address-shipping {
            margin-top: 4.5rem;
        }

        & h1 {
            font-size: 2.4rem;
            font-weight: normal;
            text-align: center;
            text-transform: uppercase;
        }
    }

    &__form-title {
        margin-bottom: 2.5rem;

        font-size: 2.2rem;
        line-height: 2.3rem;
        text-transform: uppercase;
    }

    &__form-item {
        display: block;
        margin-bottom: 2rem;

        & .wa-name {
            font-size: 1.6rem;
        }

        & .wa-name::first-letter {
            text-transform: capitalize;
        }

        & input,
        & select {
            margin-top: 0.5rem;
            padding: 1rem 1.2rem;

            font-size: 1.6rem;
        }

        & select {
            -webkit-appearance: none;

            padding: 1.05rem 1.2rem !important;
            color: var(--gray-400);
            background: var(--white) url("../img/dropdown.svg") no-repeat 96%;
        }

        & input:focus {
            border: 0.1rem solid var(--black);
        }

        & input::placeholder {
            font-family: $font-primary;
            font-size: 1.6rem;
            color: var(--gray-400);
        }
    }

    &__btn-inner {
        display: flex;
        justify-content: space-between;
        margin-top: 4.5rem;
    }

    &__form-btn {
        width: 48%;
        padding: 1.15rem 1.3rem;
        font-size: 1.6rem;
        line-height: 1.7rem;

        & svg {
            width: 1.3rem;
            height: 1.3rem;
            margin-right: 1rem;
            margin-bottom: -0.2rem;

            fill: var(--white);
        }

        & span {
            display: inline-block;
        }

        & span::first-letter {
            text-transform: capitalize;
        }
    }

    &__form-link {
        display: inline-flex;
        justify-content: center;
        width: 48%;
        padding: 1.15rem 1.2rem;

        line-height: 1.7rem;
        color: var(--gray-500);
        background-color: var(--gray-200);
        border-radius: 0.5rem;
    }

    //tabs

    &__tab-items {
        justify-content: space-between;

        & li {
            width: 25%;
        }

        & .shipping__tab-item span {
            margin-top: 0.3rem;
        }
    }

    //profile edit

    &__edit-btn {
        display: inline-flex;
        justify-content: center;

        min-width: 20rem;
        width: unset;

        margin-top: 1rem;
    }

    &__edit-item {
        display: flex;
        flex-direction: column;

        margin-bottom: 1.5rem;

        & span:first-child {
            display: inline-block;
            margin-bottom: 0.2rem;

            font-size: 1.6rem;
            color: var(--gray-500);
        }

        & span:nth-child(2),
        & a {
            font-size: 1.8rem;
            color: var(--black);
        }
    }

    //orders
    &__bonuses {
        display: none;
    }

    &__orders {
        & .tariff__table-wrap {
            min-width: 28rem;
            margin-top: 2.5rem;

            overflow: scroll;

            border: 0.1rem solid var(--gray-500);
        }
    }

    &__orders-info {
        display: flex;
        flex-direction: column;

        & span {
            font-size: 2rem;
            line-height: 2.1rem;
            text-align: center;
        }

        & span:nth-child(2) {
            display: inline-flex;
            justify-content: center;
            margin: 1.5rem auto 0;
            padding: 0.5rem 1.5rem;

            color: var(--white);
            background-color: var(--black);
            border-radius: 2.5rem;
        }
    }

    &__tariff-items {
        & th {
            padding: 0.9rem 1rem;
            font-weight: normal;
            font-size: 1.4rem;
            color: var(--gray-700);
        }

        & th:nth-child(1) {
            min-width: 7.5rem;
        }

        & th:nth-child(2),
        & th:nth-child(5),
        & th:nth-child(6) {
            min-width: 10rem;
        }

        & th:nth-child(3) {
            min-width: 21rem;
        }

        & th:nth-child(4) {
            min-width: 19rem;
        }

        & th:nth-child(5),
        & th:nth-child(6) {
            text-align: center;
        }

        & tr:not(:last-child) {
            border-bottom: 0.1rem solid var(--gray-500);
        }
    }

    &__tariff {
        & th {
            color: var(--black);
        }
    }

    //empty page
    &__empty-page {
        h1 {
            font-size: 2rem;
            margin-bottom: 2.5rem;
            text-align: center !important;
        }

        p {
            display: block;
            font-size: 1.8rem;
            text-align: center;
            color: var(--gray-500);
        }
    }

    &__btn-back {
        display: flex;
        justify-content: center;
        margin: 2.5rem auto 0;
    }

    //details

    &__details {
        h1 {
            display: flex;
            align-items: center;
            margin: 3.5rem 0;

            font-size: 2rem;
            text-transform: unset;
            text-align: left;
        }

        & h1 span {
            display: inline-block;
            margin-left: 1.5rem;
            padding: 0.5rem 1.5rem;

            font-size: 1.6rem;
            color: var(--white);
            background-color: var(--black);
            border-radius: 0.5rem;
        }

        & h3 {
            margin-bottom: 3.5rem;
        }

        & h3 {
            font-size: 1.8rem;
            font-weight: normal;
            text-transform: uppercase;
        }

        & h3 p {
            margin-top: 1rem;
            color: var(--gray-600);
        }

        & h3 span {
            display: block;
            font-size: 1.6rem;
            color: var(--gray-600);
            text-transform: initial;
        }

        & h3 span:nth-child(1) {
            margin-top: 1rem;
        }
    }

    &__detail-btn {
        display: inline-flex;
        align-items: center;
        padding: 0.75rem 1.5rem;

        font-size: 1.6rem;
        color: var(--gray-500);
        background-color: var(--white);

        & svg {
            width: 0.7rem;
            height: 1.2rem;
            margin-right: 1.1rem;
            fill: currentColor;
        }
    }

    &__details-items {
        padding: unset !important;
        margin-top: -2rem;

        & .s__final-item .s-name,
        & .s__final-item .s-value,
        & .s__final-item .s-total {
            font-size: 1.6rem;
        }

        & .s-total-wrapper {
            margin-top: 1.5rem;
            font-size: 1.6rem;
        }

        & .s-total-wrapper .s-total-sum {
            margin-bottom: unset;
        }

        & .s-total-wrapper .s-total-sum .column-2 {
            width: unset;
            margin-left: 1rem;
        }
    }

}

@include rmin($bp-tablet-m) {
    .personal-area {
        &__wrap {
            margin: 10rem 0;

            & .wa-field-address-shipping {
                margin-top: 2rem;
            }

            & h1 {
                text-align: left;
            }
        }

        &__flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__form-item {
            width: 48%;
            margin-bottom: 2.5rem;

            & input {
                padding: 1.5rem 1.2rem;
            }

            & input,
            & select {
                margin-top: 1rem;
            }

            & select {
                padding: 1.5rem 1.2rem !important;
            }
        }

        &__btn-inner {
            justify-content: flex-start;
            margin-top: 2rem;
        }

        &__form-btn {
            width: 20rem;
            margin-right: 1rem;
        }

        &__form-link {
            width: 15rem;
        }

        //tabs
        &__tab-items {
            justify-content: unset;

            & li {
                width: unset;
            }

            & .shipping__tab-item span {
                margin-top: unset;
            }
        }

        //profile edit
        &__edit-btn {
            margin-top: 2rem;
            margin-right: unset;
        }

        &__edit-item {
            & span:first-child {
                margin-bottom: unset;
            }

            & span:nth-child(2),
            & a {
                font-size: 2rem;
            }
        }

        //orders
        &__orders {
            & .tariff__table-wrap::-webkit-scrollbar {
                display: none;
            }
        }

        &__orders-info {
            flex-direction: row;
            align-items: center;

            & span:nth-child(2) {
                margin: 0 0 0 1.5rem;
            }
        }

        //empty page
        &__empty-page {
            max-width: 35rem;
            margin: 0 auto;

            h1 {
                margin-bottom: 3.5rem;
                font-size: 2.4rem;
            }

            p {
                margin-top: unset !important;
                margin-bottom: 3.5rem;
                font-size: 2rem;
            }
        }

        &__btn-back {
            display: flex;
            justify-content: center;
            margin: 2.5rem auto 0;
        }

        //favorites

        &__favorites {
            & .product__item {
                width: 31%;
            }
        }

        //details

        &__details {
            h1 {
                margin: 3.7rem 0;
                font-size: 2.4rem;
            }

            & h1 span {
                padding: 0.2rem 1.55rem;
                font-size: 1.8rem;
            }
        }

        &__details-items {
            padding: unset !important;
            margin-top: -2rem;

            & .s-column-amount,
            & .s-column-total {
                font-size: 1.6rem !important;
            }

            & .s-column-amount {
                margin-right: 3rem !important;
            }

            & .s__final-item .s-name,
            & .s__final-item .s-value,
            & .s__final-item .s-total,
            & .s-total-wrapper tr,
            & .s-total-wrapper .s-total-sum {
                font-size: 1.6rem;
            }

            & .s__final-item {
                padding: 1.2rem 1rem;
            }

            & .s-total-wrapper .column-1 {
                margin-right: 7rem;
            }

            & .s-total-wrapper .column-2 {
                margin-right: 1rem;
            }

            & .s-total-wrapper .s-total-sum .column-1 {
                margin-right: 16rem;
            }

            & thead .s-column-total {
                margin-right: 1rem;
            }

            & .s-total-wrapper tbody tr:nth-of-type(2) {
                margin-bottom: 2.5rem;
            }
        }
    }
}
