.intro {
    &__inner {
        margin-top: 1.5rem;
    }
    &__inner img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
    &__title {
        margin: 3.5rem 0 2.5rem;
        text-align: center;
    }
}
@include rmin($bp-tablet-m) {
    .intro {
        &__inner {
            margin-top: 5rem;
        }
        &__inner img {
            height: 61rem;
        }
        &__title {
            margin: 7.5rem 0 3.5rem;
        }
    }
}
