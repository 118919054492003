.sl05 {
    width: 100%;
    padding-bottom: 6rem;

    &-slide {
        display: flex;
        align-items: center;
        justify-content: center;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-bullets {
        margin-top: 1.5rem;
    }

    &-bullet {
        width: 1rem;
        height: 1rem;

        margin-right: 1rem;
        border: 0.1rem solid var(--black);

        &:hover,
        &:active,
        &:focus,
        &.glide__bullet--active {
            border: 0.1rem solid var(--black);
            background-color: var(--black);
        }

        & .glide__bullet--active {
            background: var(--black);
        }
    }
}

.produce {
    &__inner {
        margin-top: 7.5rem;
    }

    &__breadcrumb {
        display: none;
    }

    &__title-component {
        font-size: 2rem;
        text-align: center;

        & p {
            text-transform: uppercase;
        }
    }

    &__size-button {
        display: flex;
        margin-left: auto;
        margin-top: 1.5rem;

        font-size: 1.8rem;
        font-family: $font-primary;
        text-transform: uppercase;
        text-decoration: underline;

        background: transparent;
    }

    &__size-component {
        position: relative;
        margin-top: 3.5rem;
    }

    &__btn {
        width: 100%;

        margin-top: 3.5rem;
        padding: 1.6rem;

        font-size: 2.2rem;
        line-height: 2.3rem;
        text-transform: uppercase;
    }

    &__select select {
        width: 100%;
        padding-bottom: 1.5rem;
        padding-left: 2rem;

        font-size: 2rem;
        font-family: $font-primary;
        line-height: 2.1rem;
        outline: none;
        border: none;
        text-transform: uppercase;
        border-bottom: 0.1rem solid var(--gray-400);
        background: transparent;
        -webkit-appearance: none;
        background-image: url("../img/select.svg");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 25%;
    }

    &__color-choose {
        display: flex;

        margin-top: 3.5rem;
        margin-bottom: 5rem;
        overflow-x: scroll;

        & li {
            display: block;
            min-width: 31%;

            padding: 0.5rem 0;
            margin-right: 1.5rem;
            margin-top: unset;
        }

        &--active {
            border: 0.1rem solid var(--gray-400);
        }
    }

    &__color-choose::-webkit-scrollbar {
        display: none;
    }

    //accordeon
    &__accordeon-item {
        min-width: 31%;

        padding: unset !important;
        margin-top: unset;

        & a {
            width: 100%;
            text-align: center;
            color: var(--black);
        }

        p {
            margin-top: 0.8rem;
            font-size: 1.4rem;
            text-transform: uppercase;
        }

        span {
            margin-top: 0.5rem;
            font-size: 1.6rem;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    &__accordeon-inner {
        display: flex;
        margin-right: -2rem;

        overflow-x: scroll;
        color: var(--black);
    }

    &__accordeon-inner::-webkit-scrollbar {
        display: none;
    }

    //tabs
    &__tabs {
        margin-top: 7.5rem;
        margin-bottom: 10.5rem;
    }

    &__tabs-titles {
        & span:not(:last-child) {
            margin-right: 2.5rem !important;
        }

        & span {
            font-size: 1.4rem !important;
        }
    }

    &__title-fav {
        display: flex;
        flex-direction: column;

        button {
            display: none;
        }

        p {
            margin-bottom: 1.5rem;
        }
    }

    &__title-fav__btn {
        display: block !important;
        background: none;
        z-index: 2;

        order: -1;
        margin-left: auto;
        margin-top: -4rem;
        margin-bottom: 2rem;

        & svg {
            width: 2.1rem;
            height: 2.1rem;

            stroke: var(--black);
            fill: var(--white);
        }

        &--checked svg {
            fill: var(--black);
        }
    }

    &__title--crossed {
        margin-left: 1rem;
        margin-right: -5rem;

        text-decoration: line-through;
        font-size: 1.8rem;
        color: var(--gray-400);
    }

    &__content-inner {
        & li {
            margin-top: 3rem;
            text-align: center;
        }

        & .glide__bullets {
            bottom: -3rem;
        }
    }

    //popup
    &-popup__wrapper {
        opacity: 0;
        position: absolute;
        top: 5.5rem;
        z-index: 2;

        width: 100%;
        background-color: var(--white);
        border: 0.1rem solid var(--gray-300);
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);

        pointer-events: none;
        transform: translateY(100px);
        transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        will-change: transform, opacity;

        &--active {
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);
        }
    }
    &-popup__city-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 1.5rem;

        & h4 {
            margin: 0 auto;

            font-size: 1.6rem;
            font-weight: normal;
            text-transform: uppercase;
        }

        & svg {
            width: 1.5rem;
            height: 1.5rem;

            fill: none;
            stroke: var(--black);
        }
    }
    &-popup__table {
        overflow-x: scroll;
        padding: 1rem 0 2.5rem 1.5rem;

        & tr:nth-child(even) {
            background-color: var(--gray-200);
        }
        & th {
            min-width: 9rem;
            padding: 1rem 0;

            font-size: 1.3rem;
            font-weight: normal;
            line-height: 1.4rem;
        }
        & tr th:nth-of-type(1) {
            text-transform: uppercase;
        }

        & tr:nth-child(1) th {
            text-transform: unset;
        }
    }

    &-popup__footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 1.5rem;
        font-size: 1rem;
        background-color: var(--gray-200);

        & svg {
            width: 1.5rem;
            height: 1.5rem;

            margin-bottom: 0.5rem;
        }
    }
}

@include rmin($bp-phone-l) {
    .produce {
        &__color-choose li,
        &__accordeon-item {
            min-width: 23%;
        }
    }
}

@include rmin($bp-tablet-m) {
    .sl05 {
        padding-bottom: unset;

        &-slide {
            display: unset;

            & img {
                max-height: 49.4rem;
            }
        }

        &-bullets {
            display: none;
        }

        &-slides {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &-slides li {
            width: 48.5%;
            margin-bottom: 2.5rem;
        }
    }

    .produce {
        &__breadcrumb {
            display: block;
            margin-bottom: 3.5rem;
            margin-top: 10rem;
        }

        &__inner {
            position: relative;
            display: flex;
            margin-top: unset;
        }

        &__item {
            &:first-child {
                width: 60%;
                margin-right: 3.9rem;
            }

            &:last-child {
                position: sticky;
                top: 3rem;
                height: 100%;
                max-width: 47rem;
                margin-left: auto;
            }
        }

        &__title-component {
            font-size: 2.4rem;
            text-align: left;
        }

        &__title-fav {
            display: flex;
            align-items: flex-start;

            & p {
                max-width: 45rem;
            }
        }

        &__title-fav__btn {
            margin-top: unset;
            margin-bottom: -2.8rem;

            & svg {
                width: 2.3rem;
                height: 2.3rem;
            }
        }

        &__title--crossed {
            margin-left: 1rem;
            margin-right: unset;
            font-size: 2rem;
        }

        &__size-component {
            margin: 5rem 0 0 4.5rem;
        }

        //color
        &__color-choose {
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 4rem;
            overflow-x: unset;

            & li {
                min-width: 31%;
                margin-right: 0;
                margin-bottom: 1.2rem;
            }
        }

        //accordeon
        &__accordeon-inner {
            display: flex;
            flex-wrap: wrap;

            margin-right: unset;
            margin-top: 1.5rem;
            overflow-x: unset;
        }

        &__accordeon-item {
            min-width: 31%;
            margin-bottom: 1rem;

            &:nth-child(3n + 3) {
                margin-right: unset;
            }
        }

        &__accordeon-main details[open] {
            width: 39.5rem;
        }

        //tabs
        &__tabs {
            margin: 20rem 0 20rem;
        }

        &__tabs-titles span {
            font-size: 2.4rem !important;

            &:not(:last-child) {
                margin-right: 4rem !important;
            }
        }

        &__content-inner {
            margin-top: 4rem;

            & .sl05-slides li {
                width: 18%;
            }
        }

        //popup
        &-popup__wrapper {
            top: 6rem;

            width: 43rem;
            margin-left: -4rem;

            & svg {
                width: 2rem;
                height: 2rem;
            }
            & svg:hover {
                cursor: pointer;
            }
        }
        &-popup__table::-webkit-scrollbar {
            display: none;
        }
        &-popup__table th {
            text-align: center;
        }
        &-popup__footer {
            flex-direction: row;

            padding: 1.5rem 3rem;

            & svg {
                margin-bottom: unset;
                margin-right: 0.5rem;
            }
        }
    }
}

@include rmin($bp-desktop) {
    .produce {
        &-popup__wrapper {
            width: 45rem;
        }
    }
}

@include rmin($bp-desktop-s) {
    .produce {
        &-popup__wrapper {
            width: 47.5rem;
            margin-left: -7.5rem;
        }
    }
}
