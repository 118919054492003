.favorites {
    &__wrap {
        margin: 8.5rem 0 7.5rem;
    }

    &__items {
        justify-content: space-between;
    }

    &__btn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.9rem;
        height: 1.9rem;

        background-color: var(--white);
        border-radius: 50%;

        svg {
            width: 1.3rem;
            height: 1.3rem;

            stroke: var(--black);
            fill: var(--white);
        }

        &--checked svg {
            stroke: var(--black);
            fill: var(--black);
        }
    }

    &__btn-inner {
        display: none;
    }
}

//fav-empty

.fav-empty {
    &__wrap {
        margin: 8.5rem 0 7.5rem;
        text-align: center;

        & h1 {
            font-size: 2.4rem;
            font-weight: normal;
            text-transform: uppercase;
        }

        & p {
            display: block;
            margin-top: 3.5rem;

            font-size: 2rem;
            color: var(--gray-500);
        }
    }

    &__img {
        margin-top: 3.5rem;
    }
}

@include rmin($bp-tablet-m) {
    .favorites {
        &__wrap {
            margin: 10rem 0;
        }

        &__ico-btn {
            width: 2.5rem;
            height: 2.5rem;
        }

        &__item {
            margin-top: 3.5rem;
            margin-bottom: 1.5rem;
        }

        &__btn {
            width: 2.2rem;
            height: 2.2rem;
        }

        &__btn svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 1.5rem;
            height: 1.5rem;
        }

        &__btn-inner {
            margin-top: 3.5rem;
        }
    }

    //fav-empty

    .fav-empty {
        &__wrap {
            max-width: 35rem;
            margin: 15rem auto 15rem;
        }
    }
}
