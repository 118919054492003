// breakpoints
$bp-phone-xs: 320;
$bp-phone-s: 375;
$bp-phone-m: 414;
$bp-phone-l: 500;
$bp-tablet: 768;
$bp-tablet-m: 1024;
$bp-desktop: 1200;
$bp-desktop-s: 1366;
$bp-desktop-m: 1600;
$bp-desktop-l: 1920;
$max-width: 123rem;

// fonts
$font-primary: "questrial", sans-serif;

// colors
$font-color: var(--black);
$gray: #888888;
$success: #66cc4a;
$danger: #ce2f20;
$warning: #e59824;

// $secondary: #1479fb;
$primary: #1479fb;

:root {
    --black: #000000;
    --white: #ffffff;

    --primary-700: #{darken($primary, 20%)};
    --primary-600: #{darken($primary, 10%)};
    --primary-400: #{lighten($primary, 10%)};
    --primary-300: #{lighten($primary, 20%)};
    --primary-200: #{lighten($primary, 30%)};
    --primary-100: #{lighten($primary, 40%)};
    --primary-light: #{lighten($primary, 45%)};

    // --secondary-700: #{darken($secondary, 20%)};
    // --secondary-600: #{darken($secondary, 10%)};
    // --secondary-400: #{lighten($secondary, 10%)};
    // --secondary-300: #{lighten($secondary, 20%)};
    // --secondary-200: #{lighten($secondary, 30%)};
    // --secondary-100: #{lighten($secondary, 40%)};
    // --secondary-light: #{lighten($secondary, 45%)};

    --gray-700: #{darken($gray, 20%)};
    --gray-600: #{darken($gray, 10%)};
    --gray-500: #{darken($gray, 0%)};
    --gray-400: #{lighten($gray, 10%)};
    --gray-300: #{lighten($gray, 20%)};
    --gray-200: #f2f2f2;
    --gray-100: #f9f9f9;
    --gray-50: #fbfbfb;

    --success-600: #{darken($success, 10%)};
    --success-100: #{lighten($success, 40%)};

    --danger-600: #{darken($danger, 10%)};
    --danger-100: #{lighten($danger, 45%)};

    --warning-600: #{darken($warning, 10%)};
    --warning-100: #{lighten($warning, 40%)};
}
