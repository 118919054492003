.btn {
    padding: 1rem 2rem;

    font-size: 1.4rem;
    font-family: $font-primary;
    line-height: 1.5rem;

    border-radius: 0.5rem;

    &--primary {
        color: var(--white);
        background-color: var(--black);
    }
    &--outline {
        background-color: var(--white);
        border: 0.1rem solid var(--black);
    }
}

@include rmin($bp-tablet-m) {
    .btn {
        padding: 1.6rem 2.5rem;
        font-size: 1.6rem;
    }
}
